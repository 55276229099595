<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockInstructionAdd" />
      <!-- 検索項目 -->
      <v-form ref="form">
        <v-container fluid>
          <!-- 検索条件タイトル -->
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <div
              class="display-search-result-row"
              style="float: left; margin-top: 0px; padding-bottom: 20px"
            >
              <span class="toatlNum">{{ searchConditionTitle }}</span>
            </div>
          </v-row>
          <!-- １行目スタート -->
          <v-row class="search-row" style="margin-bottom: 10px">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
              <v-autocomplete
                dense
                id="get-supplier"
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :rules="[rules.supplierRequired]"
                persistent-hint
                :hint="setSrhSupplier()"
                :error-messages="alertSupplierMessage"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 品番 -->
            <div class="search-autocomplete" style="float: left; width: 300px">
              <v-autocomplete
                dense
                v-model="itemCdSelected"
                :items="itemCdList"
                :label="$t('label.lbl_productCode')"
                :error-messages="alertItemCdMessage"
                @change="(event) => changeProductNm(event)"
                :search-input.sync="search"
                :hint="setItemCd()"
                :rules="[rules.inputRequired]"
                class="txt-single"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 品質区分 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="qualityDivSelected"
                :items="qualityDivList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
          </v-row>
          <v-row class="search-row">
            <!-- ロット -->
            <div class="serch-textbox-arrivalNo first-search-item">
              <v-text-field
                outlined
                dense
                v-model="txt_lotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="serial"
                maxlength="32"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 期限日 -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="dueDate"
                maxlength="8"
                :label="$t('label.lbl_dueDate')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー１ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotSubkey1"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey1')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー２ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotSubkey2"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey2')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>
          <!-- 登録画面タイトル -->
          <v-row>
            <div
              class="display-search-result-row"
              style="float: left; margin-top: 0px; padding-bottom: 20px; padding-top: 5px"
            >
              <span class="toatlNum">{{ insertScreenTitle }}</span>
            </div>
          </v-row>
          <v-row class="search-row">
            <div style="float: left; width: 1350px">
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 指示内容 -->
              <div class="search-autocomplete" style="float: left; width: 300px">
                <v-autocomplete
                  dense
                  v-model="instructionContentSelected"
                  :items="instructionContentList"
                  :label="$t('label.lbl_instructionContent')"
                  :hint="setInstructionContent()"
                  :error-messages="alertInstructionContentMessage"
                  class="txt-single"
                  persistent-hint
                  :rules="[rules.inputRequired]"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span></template
                  >
                </v-autocomplete>
              </div>
              <span class="require" style="float: left">*</span>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
              <span class="item-spacer">&nbsp;</span>
              <div
                class="search-autocomplete first-search-item"
                style="float: left; height: 80px; width: 950px"
              >
                <!--変更後品番/品名-->
                <div
                  class="search-autocomplete"
                  style="float: left; width: 450px"
                  v-if="instructionContentSelected == '04'"
                >
                  <v-autocomplete
                    dense
                    v-model="changedItemCdSelected"
                    :items="changedItemCdList"
                    :label="$t('label.lbl_afterChangeItemCdName')"
                    :error-messages="alertChangedItemCdMessage"
                    @change="(event) => afterChangedProductNm(event)"
                    :search-input.sync="afterItemCdSearch"
                    :hint="setItemCdChange()"
                    :rules="[rules.inputRequired]"
                    class="txt-single"
                    persistent-hint
                    autocomplete="off"
                  >
                    <template slot="item" slot-scope="data">
                      <span class="auto-txt-size"> {{ data.item.text }} </span></template
                    >
                  </v-autocomplete>
                </div>
                <span class="require" v-if="instructionContentSelected == '04'" style="float: left"
                  >*</span
                >

                <!--変更後ロット-->
                <span class="item-spacer">&nbsp;</span>
                <div
                  class="serch-textbox-arrivalNo"
                  style="float: left; width: 300px"
                  v-if="instructionContentSelected == '02'"
                >
                  <div class="serch-textbox-arrivalNo" style="width: 300px">
                    <v-text-field
                      class="txt-single"
                      outlined
                      dense
                      v-model="changedLot"
                      maxlength="20"
                      clear-icon="mdi-close-circle"
                      clearable
                      :label="$t('label.lbl_afterChangeLot')"
                      :error-messages="alertChangedLot"
                    />
                  </div>
                </div>

                <!--変更後名義-->
                <div
                  class="search-autocomplete"
                  style="float: left; width: 450px"
                  v-if="instructionContentSelected == '03'"
                >
                  <v-autocomplete
                    dense
                    v-model="changedSuppliersSelected"
                    :items="changedSupplierList"
                    :label="$t('label.lbl_afterChangeSupplier')"
                    class="txt-single"
                    :hint="setChangedSrhSupplier()"
                    persistent-hint
                    :rules="[rules.inputRequired]"
                    pa-3
                    :error-messages="alertChangedSupplierMessage"
                  >
                    <template slot="item" slot-scope="data">
                      <span class="auto-txt-size"> {{ data.item.text }} </span>
                    </template>
                  </v-autocomplete>
                </div>
                <span class="require" v-if="instructionContentSelected == '03'" style="float: left"
                  >*</span
                >
                <span class="item-spacer">&nbsp;</span>

                <!-- 変更後ステータス-->
                <div
                  v-if="
                    instructionContentSelected == '01' ||
                    instructionContentSelected == '' ||
                    instructionContentSelected == null
                  "
                  class="search-autocomplete"
                  style="float: left; width: 300px"
                >
                  <v-autocomplete
                    dense
                    v-model="changedStatusSelected"
                    :items="changedStatusList"
                    :hint="changedStatus()"
                    maxlength="10"
                    :label="$t('label.lbl_afterChangeStatus')"
                    persistent-hint
                    :error-messages="alertMessage"
                    :rules="[rules.inputRequired]"
                  >
                    >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
                <span
                  class="require"
                  v-if="
                    instructionContentSelected == '01' ||
                    instructionContentSelected == '' ||
                    instructionContentSelected == null
                  "
                  style="float: left"
                  >*</span
                >
              </div>

              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
              <!-- 指示No -->
              <div class="search-autocomplete first-search-item" style="float: left; width: 300px">
                <v-text-field
                  outlined
                  dense
                  v-model="txt_instructionNo"
                  maxlength="50"
                  :label="$t('label.lbl_instructionNo')"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  :rules="[rules.inputRequired]"
                  clearable
                />
              </div>
              <span class="require" style="float: left">*</span>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
              <!-- 変更理由-->
              <div class="search-autocomplete first-search-item" style="float: left">
                <v-autocomplete
                  dense
                  v-model="reasonChangeSelected"
                  :items="reasonChangeList"
                  :label="$t('label.lbl_reasonChange')"
                  :hint="setReasonChange()"
                  class="txt-single"
                  persistent-hint
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!--有償無償-->
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <div style="float: left">
                <v-btn-toggle
                  mandatory
                  v-model="isPaid"
                  color="blue darken-2"
                  id="btn-toggle-btn-padding"
                >
                  <v-btn
                    class="paid-btn"
                    v-for="item in freeCostDivList"
                    :key="item.value"
                    :value="item.value"
                    small
                  >
                    {{ item.text }}</v-btn
                  >
                </v-btn-toggle>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 無償理由選択プルダウン-->
              <div class="search-autocomplete" style="float: left">
                <v-autocomplete
                  dense
                  class="list-single"
                  v-model="freeReasonSelected"
                  :items="freeReasonList"
                  v-bind:disabled="isDisabledFreeReason"
                  :label="$t('label.lbl_freeReason')"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!-- 作業者 -->
              <div class="search-autocomplete first-search-item" style="float: left; width: 300px">
                <v-autocomplete
                  dense
                  v-model="workerSelected"
                  :items="workerList"
                  :label="$t('label.lbl_worker')"
                  :hint="setWorker()"
                  :error-messages="alertWorkerMessage"
                  class="txt-single"
                  persistent-hint
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>
            </div>
            <!-- 備考欄-->
            <div class="search-autocomplete first-search-item" style="width: 480px; height: 100px">
              <v-textarea
                outlined
                v-model="remarks"
                :maxlength="200"
                no-resize
                :label="$t('label.lbl_remarksTextbox')"
                rows="4"
                :rules="[rules.remark]"
              />
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" id="get-search" class="api-btn" @click="searchApi(true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
              </div>
              <!--ボタン領域-->
              <div class="btn-area" style="float: right">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">
                  {{ $t("btn.btn_back") }}
                </v-btn>
                <!-- 空白 -->
                <span class="item-spacer">&nbsp;</span>
                <!-- 登録ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="viewConfirmDialog('btn_insert')"
                >
                  {{ $t("btn.btn_insert") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          ref="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
          :sort-by.sync="sortBy"
          height="450px"
          disable-pagination
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
              @click="checkList(item, index)"
            />
          </template>

          <!-- 品番/品名 -->
          <template v-slot:[`item.itemCdName`]="{ item }">
            <div class="align-left">{{ item.itemCd }}</div>
            <div class="align-left">{{ item.incidental }}</div>
            <div class="align-left">{{ item.itemName }}</div>
          </template>
          <!-- ステータス -->
          <template v-slot:[`item.status`]="{ item }">
            <div class="align-left">{{ item.status }}</div>
          </template>
          <!-- 倉庫 -->
          <template v-slot:[`item.warehouse`]="{ item }">
            <div class="align-left">{{ item.warehouse }}</div>
          </template>
          <!-- ロケ -->
          <template v-slot:[`item.location`]="{ item }">
            <div class="align-left">{{ item.location }}</div>
          </template>
          <!-- ロット/S/N -->
          <template v-slot:[`item.lot`]="{ item }">
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="width: 400px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </template>
          <!-- 入庫日 -->
          <template v-slot:[`item.enteringDate`]="{ item }">
            <div class="align-left">{{ item.enteringDate }}</div>
          </template>
          <!-- 数量 -->
          <template v-slot:[`item.stockQty`]="{ item }">
            <div class="align-right">{{ item.stockQty }}</div>
          </template>
          <!--変更数量-->
          <template v-slot:[`item.inputQuantity`]="{ item, value, index }">
            <v-text-field
              v-model="item.inputQuantity"
              class="input-number"
              :value="value"
              outlined
              dense
              maxLength="22"
              @load="value = item.inputQuantity"
              :error-messages="alertInputQuantity"
              :id="'inputQuantity' + index"
              autocomplete="off"
              :rules="[
                rules.isNumber,
                rules.isInteger,
                instructionContentSelected === '05' ? true : rules.isRangeCheck,
                rules.limitNumLength,
              ]"
            >
            </v-text-field>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000">
          <StockInquiryDetails
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :stockInstructionAddFlg="infoDialog.stockInstructionAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :screenFlag="confirmDialog.screenFlag"
      />

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import StockInquiryDetails from "../stock/StockInquiryDetails.vue";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.SCREEN_ID.P_STC_003,
  components: {
    Loading,
    sideMenu,
    StockInquiryDetails,
    NavBar,
    ConfirmDialog,
    SimpleDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    // 詳細画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      qualityDiv: "",
      itemName: "",
      itemStandardSid: "",
    },
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) =>
        1 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMore"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
      remark: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          if (value.replace(/\r?\n/g, "").length > 200) {
            return i18n.tc("check.chk_input_200");
          }
        }
        return true;
      },
    },
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // 選択された取引先
    suppliersSelected: "",
    // 選択された品質区分
    qualityDivSelected: "",
    // 選択された品番/品名
    nameSelected: "",
    // メニュー
    openMenu: null,
    // 詳細ポップアップ
    dialogDetails: false,
    // ソート
    sortItem: "",
    sortOptions: {},
    sortBy: "",
    // 指示No
    txt_instructionNo: "",
    // 備考欄
    remarks: "",
    // 有償無償
    freeCostDivList: [],
    // 無償・有償
    isPaid: "01",
    // 変更理由
    reasonChangeList: [],
    reasonChangeSelected: "",
    // 無償理由
    freeReasonSelected: "",
    freeReasonList: [],
    isDisabledFreeReason: true,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      stockInstructionAddFlg: false,
      firstPageFlag: false,
      thirdPageFlag: false,
      screenFlag: false,
    },
    // 作業者
    workerSelected: "",
    workerList: [],
    // エラーメッセージ
    alertWorkerMessage: "",
    alertItemCdMessage: "",
    alertInstructionNoMessage: "",
    alertInstructionContentMessage: "",
    alertMessage: "",
    alertSupplierMessage: "",
    alertInputQuantity: "",
    // 検索条件タイトル
    searchConditionTitle: "<検索内容>",
    // 登録画面タイトル
    insertScreenTitle: "<登録内容>",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // ロットNo
    txt_lotNo: "",
    //S/N
    serial: "",
    //期限日
    dueDate: "",
    //付属キー１
    lotSubkey1: "",
    //付属キー２
    lotSubkey2: "",
    // 削除リスト
    deleteList: [],
    // 品番
    itemCdSelected: "",
    itemCdList: [],
    // 指示内容
    instructionContentSelected: "",
    instructionContentList: [],
    // 変更後ステータス
    changedStatusSelected: "",
    changedStatusList: [],
    // 変更後品番・品名
    changedItemCdSelected: "",
    changedItemCdList: [],
    alertChangedItemCdMessage: "",
    afterItemCdSearch: "",
    // 変更後ロット
    changedLot: "",
    alertChangedLot: "",
    // 変更後名義
    changedSuppliersSelected: "",
    changedSupplierList: [],
    alertChangedSupplierMessage: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 一覧画面から情報
    searchParam: "",
    // 選択行
    selectedIndex: 0,
    // ヘッダ
    headerItems: [
      // チェックボックス
      { text: "選択", value: "check", width: "3%", align: "center", sortable: false },
      // 品番
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemCdName",
        width: "18%",
        align: "left",
        sortable: false,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "12%",
        align: "left",
        sortable: true,
      },
      // ロケ
      {
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "22%",
        align: "left",
        sortable: true,
      },
      // 入荷日
      {
        text: i18n.tc("label.lbl_arrivalDate"),
        value: "enteringDate",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "stockQty",
        width: "8%",
        align: "right",
        sortable: true,
      },
      // 変更数量
      {
        text: i18n.tc("label.lbl_quantityChange"),
        value: "inputQuantity",
        width: "8%",
        align: "right",
        sortable: true,
        class: "asta",
      },
    ],
    inputList: [],
    // 検索結果
    stockList: [],
    // 取引先プルダウン
    supplierList: [],
    // 品質区分
    qualityDivList: [],
    suppliersSelectedName: "",
    lot: "",
    qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
    // 品番品名プルダウン
    nameList: [],
    // 検索条件保持
    searchSuppliersSelected: "",
    // 名義変更：品番リスト
    itemList: [],
    itemCdAfter: "",
    // itemSidAfter: "",
  }),
  methods: {
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      let cnt = 0;
      switch (button) {
        case "btn_insert":
          if (this.listCheckboxBtn.length == 0 || this.listCheckboxBtn.length < 0) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
            return;
          }
          // メッセージ初期化;
          this.alertMessage = "";
          this.alertInstructionContentMessage = "";
          this.alertChangedItemCdMessage = "";
          this.alertChangedLot = "";
          this.alertChangedSupplierMessage = "";
          // 必要チェック
          // 取引先チェック
          if (this.suppliersSelected == "" || this.suppliersSelected == null) {
            this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
            return;
          }
          // 品番チェック
          if (this.itemCdSelected == "" || this.itemCdSelected == null) {
            this.alertItemCdMessage = i18n.tc("check.chk_input");
            return;
          }
          // 変更指示チェック
          if (this.instructionContentSelected == null || this.instructionContentSelected == "") {
            this.alertInstructionContentMessage = i18n.tc("check.chk_input");
            return;
          }
          if (this.instructionContentSelected == "01") {
            // ステータス変更の場合
            if (this.changedStatusSelected == null || this.changedStatusSelected == "") {
              this.alertMessage = i18n.tc("check.chk_input");
              return;
            }
          } else if (this.instructionContentSelected == "04") {
            // 品番変更の場合
            if (this.changedItemCdSelected == null || this.changedItemCdSelected == "") {
              this.alertChangedItemCdMessage = i18n.tc("check.chk_input");
              return;
            }
          } else if (this.instructionContentSelected == "03") {
            // 名義変更の場合
            if (this.changedSuppliersSelected == null || this.changedSuppliersSelected == "") {
              this.alertChangedSupplierMessage = i18n.tc("check.chk_input");
              return;
            }
          } else {
            // 処理しない
          }

          for (var i = 0; i < this.inputList.length; i++) {
            if (this.inputList[i].check) {
              // 数量フォーマット修正
              var checkInputQuantity = Number(
                commonUtil.zen2han(this.inputList[i].inputQuantity).replaceAll(",", "")
              );
              var checkStockQty = Number(
                commonUtil.zen2han(this.inputList[i].stockQty).replaceAll(",", "")
              );
              if (this.instructionContentSelected !== "05" && checkInputQuantity > checkStockQty) {
                this.updateDialogMessage = true;
                this.backMessage = messsageUtil.getMessage("P-STC-001_002_E");
                return;
              }
              if (this.instructionContentSelected == "01") {
                // ステータスチェック
                if (this.changedStatusSelected == this.inputList[i].qualityDiv) {
                  this.updateDialogMessage = true;
                  this.backMessage = messsageUtil.getMessage("P-STC-001_003_E");
                  return;
                }
              } else if (this.instructionContentSelected == "02") {
                // ロットチェック
                if (this.changedLot == this.inputList[i].lot) {
                  this.updateDialogMessage = true;
                  this.backMessage = messsageUtil.getMessage("P-STC-001_004_E");
                  return;
                }
              } else if (this.instructionContentSelected == "04") {
                // 品番チェック
                if (this.changedItemCdSelected == this.inputList[i].itemStandardSid) {
                  this.updateDialogMessage = true;
                  this.backMessage = messsageUtil.getMessage("P-STC-001_005_E");
                  return;
                }
              } else {
                // 処理しない
              }

              // 数量チェック
              if (this.instructionContentSelected !== "05" && this.checkInputQuantity == 0) {
                // 数量が0の場合、警告メッセージ表示
                cnt = i + 1;
              } else {
                // 処理なし
              }

              if (checkInputQuantity == "") {
                // 数量が未入力時
                cnt = i + 1;
              } else {
                // 処理なし
              }

              // 数値チェック
              if (isNaN(checkInputQuantity)) {
                // 数量が数値でない時
                cnt = i + 1;
              } else {
                // 処理なし
              }

              // 整数チェック
              if (Number.isInteger(checkInputQuantity)) {
                // 処理なし
              } else {
                // 数量が整数でない時
                cnt = i + 1;
              }

              // 範囲チェック（0未満）
              if (0 <= checkInputQuantity) {
                // 処理なし
              } else {
                // 数量が0未満の場合
                cnt = i + 1;
              }
              if (cnt != 0) {
                let checkPosition = String(i);
                if (!this.inputList[i].checkInputQuantity) {
                  // 関係ないレコードの入力チェックエラーを解除
                  eval("this.$refs.checkInputQuantity" + checkPosition + ".resetValidation()");
                } else {
                  // 処理なし
                }
              }
            }
          }

          isValidationRequired = true;
          message = messsageUtil.getMessage("P-RCV-001_003_C");
          action = this.registration;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.back;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // let isValid = this.$refs.editedList.validate();
        // if (!isValid) {
        //   return;
        // }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    // 初期化
    init() {
      // 一覧画面からパラメータを受け取り
      if (this.$route.params.addSearchParam != null) {
        this.searchParam = this.$route.params.addSearchParam;
      }
      // マスタ取得
      this.getMstCode();
      this.getWorker();
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        this.inputList[index].check = true;

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合
        this.inputList[index].check = false;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check != false) {
            this.listCheckboxBtn.push(this.inputList[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
        if (this.listCheckboxBtn.length < this.inputList.length) {
          this.isAllSelected = false;
        }

        if (this.listCheckboxBtn.length == 0 && this.chengeListCheckbox.length == 0) {
          this.isAllSelected = false;
        }
      }
    },

    // 取引先コードセット処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    getClientName() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].text;
        }
      }
    },
    // 変更後名義コードセット処理
    setChangedSrhSupplier() {
      for (var i = 0; i < this.changedSupplierList.length; i++) {
        if (this.changedSupplierList[i].value == this.changedSuppliersSelected) {
          return this.changedSupplierList[i].name;
        }
      }
    },
    // 商品コードセット処理
    changedStatus() {
      for (var i = 0; i < this.changedStatusList.length; i++) {
        if (this.changedStatusList[i].value == this.changedStatusSelected) {
          return this.changedStatusList[i].name;
        }
      }
    },
    // 品質区分セット処理
    setStsArrival() {
      for (var i = 0; i < this.qualityDivList.length; i++) {
        if (this.qualityDivList[i].value == this.qualityDivSelected) {
          return this.qualityDivList[i].name;
        }
      }
    },
    /**
     * 検索エリア：品番リストセット処理
     */
    setItemCd() {
      for (var i = 0; i < this.itemCdList.length; i++) {
        if (this.itemCdList[i].value == this.itemCdSelected) {
          return this.itemCdList[i].name;
        }
      }
    },
    /**
     * 検索エリア：品番リストセット処理
     */
    searchItemCd() {
      for (var i = 0; i < this.itemCdList.length; i++) {
        if (this.itemCdList[i].value == this.itemCdSelected) {
          return this.itemCdList[i].code;
        }
      }
    },
    /**
     * 変更エリア：品番リストセット処理
     */
    setItemCdChange() {
      for (var i = 0; i < this.changedItemCdList.length; i++) {
        if (this.changedItemCdList[i].value == this.changedItemCdSelected) {
          return this.changedItemCdList[i].name;
        }
      }
    },
    /**
     * 変更後品番：品番取得
     */
    getItemCdChange() {
      for (var i = 0; i < this.changedItemCdList.length; i++) {
        if (this.changedItemCdList[i].value == this.changedItemCdSelected) {
          return this.changedItemCdList[i].code;
        }
      }
    },

    /**
     * 指示内容リストセット処理
     */
    setInstructionContent() {
      for (var i = 0; i < this.instructionContentList.length; i++) {
        if (this.instructionContentList[i].value == this.instructionContentSelected) {
          return this.instructionContentList[i].name;
        }
      }
    },
    // 担当者プルダウン
    getWorker() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 担当者
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.searchCategoryDiv = "0";

      // console.debug("getManager() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getManager() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                });
              });

              // 画面の初期値を設定します。
              this.workerList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 詳細データテーブル設定
    customHeader(headerLabel) {
      let header = [];
      headerLabel.forEach((value) => {
        let headerItem = this.headerItems.filter((item) => item.value === value);
        if (headerItem.length > 0) {
          header.push(headerItem[0]);
        }
      });
      this.headerItems = header;
    },
    /**
     *在庫情報変更指示登録画面： 検索ボタン押下処理
     */
    searchApi() {
      this.inputList = [];
      this.listCheckboxBtn = [];
      this.sortBy = null;
      // メッセージ初期化
      this.alertSupplierMessage = "";
      this.alertItemCdMessage = "";
      // 取引先存在チェック
      if (this.suppliersSelected == "" || this.suppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_input");
        return;
      }
      if (this.itemCdSelected == "" || this.itemCdSelected == null) {
        this.alertItemCdMessage = i18n.tc("check.chk_input");
        return;
      } else {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const config = this.$httpClient.createGetApiRequestConfig();

        // 検索条件などのパラメータを設定する
        config.params.itemStandardSid = this.itemCdSelected;
        config.params.clientSid = this.suppliersSelected;
        //ロット
        if (this.txt_lotNo) {
          config.params.lotNo = this.txt_lotNo;
        }
        //S/N
        if (this.serial) {
          config.params.serial = this.serial;
        }
        //期限日
        if (this.dueDate) {
          config.params.dueDate = this.dueDate;
        }
        //付属キー１
        if (this.lotSubkey1) {
          config.params.lotSubkey1 = this.lotSubkey1;
        }
        //付属キー２
        if (this.lotSubkey2) {
          config.params.lotSubkey2 = this.lotSubkey2;
        }
        //品質区分
        if (this.qualityDivSelected) {
          config.params.qualityDiv = this.qualityDivSelected;
        }
        // 入力の取引先取得
        // this.searchSuppliersSelected = this.suppliersSelected;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_STOCKDTL_LOT, config)
            .then((response) => {
              const list = [];
              const jsonData = JSON.parse(JSON.stringify(response.data));
              this.stockList = jsonData.resIdv.stocksDetail;

              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                for (let i = 0; i < this.stockList.length; i++) {
                  let lotNoObj = commonUtil.convertLotNo(this.stockList[i].lotNo);
                  list.push({
                    itemCdShow: this.stockList[i].itemCd,
                    itemCd: this.stockList[i].itemCd.substring(0, 50).trimRight(),
                    incidental: this.stockList[i].itemCd.slice(-50).trimRight(),
                    itemName: this.stockList[i].itemName,
                    status: this.stockList[i].qualityDivName,
                    warehouse: this.stockList[i].warehouseName,
                    location: this.stockList[i].locationNo,
                    lotShow: this.stockList[i].lotNo,
                    lot: lotNoObj.lot,
                    serial: this.stockList[i].lotNo.slice(-80).trimRight(),
                    dueDate: lotNoObj.dueDate,
                    enteringDate: this.stockList[i].yyyymmdd,
                    stockQty: commonUtil.formatToCurrency(Number(this.stockList[i].stocks)),
                    inputQuantity: commonUtil.formatToCurrency(Number(this.stockList[i].stocks)),
                    itemManageNo: this.stockList[i].itemManageNo,
                    warehouseSid: this.stockList[i].warehouseSid,
                    locationSid: this.stockList[i].locationSid,
                    itemStandardSid: this.stockList[i].itemStandardSid,
                    qualityDiv: this.stockList[i].qualityDiv,
                    check: false,
                  });
                }
                this.inputList = list;
                // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                this.listCheckboxBtn.length = 0;
                this.isAllSelected = false;

                // エラー時
              } else {
                // エラーの場合
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                this.infoDialog.screenFlag = true;
                reject();
              }
              resolve(response);
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    // 在庫指示No採番
    setInstructionNo() {
      // 取引先Cdと営業所Cdをセット
      this.clientCd = this.suppliersSelected;
      this.officeCd = sessionStorage.getItem("office_cd");
      // 現在日付を編集
      var str = dateTimeHelper.convertJST();
      // ハイフン・時間カット
      str = str.split("/").join("").slice(0, 8);
      // 指示Noにセット
      this.txt_instructionNo = `${this.officeCd}-${this.clientCd}-${str}`;
    },
    /**
     * 登録処理
     */
    registration() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STC_003;
      // 修正ﾘｽﾄ抽出
      var bodyList = [];
      // 実績を追加したレコードのみループを回し、数量を設定。
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          var inputMap = {};
          // 営業所SID（ログインユーザ情報）
          // inputMap.officeSid = sessionStorage.getItem("sales_office_sid");
          // 処理区分
          inputMap.procDiv = "1";
          // 指示No
          inputMap.stockStateChangeNo = this.txt_instructionNo;
          // 指示日
          let instDateJST = dateTimeHelper.convertJST().substr(0, 10);
          inputMap.instDate = dateTimeHelper.convertUTC(instDateJST);
          // 在庫状態変更区分（指示内容区分）
          inputMap.stockStateChangeDiv = this.instructionContentSelected;
          // 変更理由区分
          if (this.reasonChangeSelected) {
            inputMap.changeReasonDiv = this.reasonChangeSelected;
          } else {
            inputMap.changeReasonDiv = "";
          }
          // 有償無償区分
          if (this.isPaid) {
            inputMap.freeCostDiv = this.isPaid;
          } else {
            inputMap.freeCostDiv = "";
          }
          // 無償理由区分
          if (this.freeReasonSelected) {
            inputMap.freeCostReasonDiv = this.freeReasonSelected;
          } else {
            inputMap.freeCostReasonDiv = "";
          }
          // ユーザSID
          if (this.workerSelected) {
            inputMap.userSid = this.workerSelected;
          } else {
            inputMap.userSid = "";
          }
          // 備考
          if (this.remarks) {
            inputMap.remarks = this.remarks;
          } else {
            inputMap.remarks = "";
          }
          // 変更前品番
          inputMap.itemCdBefore = this.inputList[i].itemCdShow;
          // 変更後後品番
          if (this.instructionContentSelected == "04") {
            inputMap.itemCdAfter = this.getItemCdChange();
          } else {
            inputMap.itemCdAfter = this.inputList[i].itemCdShow;
          }
          // 変更前倉庫SID
          inputMap.warehouseSidBefore = this.inputList[i].warehouseSid;
          // 変更後倉庫SID
          inputMap.warehouseSidAfter = this.inputList[i].warehouseSid;
          // 変更前ロケーションSID
          inputMap.locationSidBefore = this.inputList[i].locationSid;
          // 変更後ロケーションSID
          inputMap.locationSidAfter = this.inputList[i].locationSid;
          // 変更前商品標準SID
          inputMap.itemStandardSidBefore = this.inputList[i].itemStandardSid;
          // 変更後商品標準SID
          if (this.instructionContentSelected == "04") {
            inputMap.itemStandardSidAfter = this.changedItemCdSelected;
          } else if (this.instructionContentSelected == "03") {
            inputMap.itemStandardSidAfter = "";
          } else {
            inputMap.itemStandardSidAfter = this.inputList[i].itemStandardSid;
          }
          // 変更前ロット
          inputMap.lotNoBefore = this.inputList[i].lotShow;
          // 変更後ロット
          if (this.instructionContentSelected == "02") {
            if (this.changedLot != null && this.changedLot != "") {
              inputMap.lotNoAfter =
                this.changedLot.padEnd(20, " ") + this.inputList[i].serial.padEnd(80, " ");
            } else {
              inputMap.lotNoAfter = "".padEnd(20, " ") + this.inputList[i].serial.padEnd(80, " ");
            }
          } else {
            inputMap.lotNoAfter = this.inputList[i].lotShow;
          }
          // 変更前数量
          inputMap.stockBefore = this.inputList[i].stockQty.replaceAll(",", "");
          // 変更後数量
          inputMap.stockAfter = this.inputList[i].inputQuantity.replaceAll(",", "");
          // 管理番号
          inputMap.itemManageNo = this.inputList[i].itemManageNo;
          // 変更前品質区分
          inputMap.qualityDivBefore = this.inputList[i].qualityDiv;
          // 変更後品質区分
          if (this.instructionContentSelected == "01") {
            if (this.changedStatusSelected != null && this.changedStatusSelected != "") {
              inputMap.qualityDivAfter = this.changedStatusSelected;
            } else {
              // 処理しない
            }
          } else {
            inputMap.qualityDivAfter = this.inputList[i].qualityDiv;
          }
          // 変更前取引先
          inputMap.clientSidBefore = this.suppliersSelected;
          // 変更後取引先SID
          if (this.instructionContentSelected == "03") {
            inputMap.clientSidAfter = this.changedSuppliersSelected;
          } else {
            inputMap.clientSidAfter = this.suppliersSelected;
          }

          bodyList.push(inputMap);
        } else {
          // 実績を追加していないレコードは、追加しない
        }
      }
      body.reqIdv.stockStateChanges = bodyList;
      // console.debug("registration() Body", body);
      console.log(body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_STOCK_INSTRUCTION_ADD, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("registration() Response", response);

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.stockInstructionAddFlg = true;
              this.infoDialog.firstPageFlag = false;
              // リストクリア
              this.inputList = [];
              this.listCheckboxBtn = [];
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.stockInstructionAddFlg = false;
              this.infoDialog.screenFlag = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.stockInstructionAddFlg = false;
            this.infoDialog.screenFlag = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 変更理由リストセット処理
     */
    setReasonChange() {
      for (var i = 0; i < this.reasonChangeList.length; i++) {
        if (this.reasonChangeList[i].value == this.reasonChangeSelected) {
          return this.reasonChangeList[i].name;
        }
      }
    },
    /**
     * 在庫情報変更指示登録：戻るボタン処理
     */
    back() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_STC_001,
        params: {
          returnParam: this.searchParam,
          backPage: "StockUpdateInstructionAdd",
        },
      });
    },

    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 変更内容
      const instructionList = getParameter.getCodeMst(appConfig.CODETYPE.INSTRUCTION_CONTENT);
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 変更理由
      const reasonChagedList = getParameter.getCodeMst(appConfig.CODETYPE.INSTRUCTION_REASON);
      Promise.all([
        reasonList,
        processList,
        freeCostList,
        instructionList,
        clientList,
        qualityList,
        reasonChagedList,
      ])
        .then((result) => {
          // 画面の初期値を設定します。
          this.freeReasonList = result[0];
          this.processDivList = result[1];
          this.freeCostDivList = result[2];
          this.instructionContentList = result[3].filter((r) => {
            // 在庫調整指示はPCから作成不可能
            return r.value !== "05";
          });
          this.supplierList = result[4];
          this.qualityDivList = result[5];
          this.changedStatusList = result[5];
          this.reasonChangeList = result[6];

          // 初期設定
          // 指示内容リスト
          this.instructionContentSelected = this.instructionContentList[0].value;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 変更後取引ドロップダウン取得
     */
    setChangedSupplierList() {
      let list = this.supplierList.filter((val) => {
        return val.value != this.suppliersSelected;
      });
      this.changedSupplierList = list;
    },
    /**
     * 作業者リストセット処理
     */
    setWorker() {
      for (var i = 0; i < this.workerList.length; i++) {
        if (this.workerList[i].value == this.workerSelected) {
          return this.workerList[i].name;
        }
      }
    },
    /**
     * 検索エリア：品名取得プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.itemCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.itemCdList = this.itemCdList.filter((v) => v.value == value);
    },
    /**
     * 変更エリア：品名取得プルダウンチェンジイベント
     */
    afterChangedProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.changedItemCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.changedProductHint = val.text;
        this.afterItemCdSearch = val.text;
      }
      this.changedItemCdList = this.changedItemCdList.filter((v) => v.value == value);
    },

    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // ダイアログオープン
    openPopup() {
      this.dialogDetails = true;
    },
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },
    // OKボタン処理
    updateOk() {
      this.updateDialogMessage = false;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    instructionContentSelected(newValue) {
      if (newValue || null) {
        this.instructionContentSelected = newValue;
        this.changedStatus();
        this.setItemCdChange();
        this.setChangedSrhSupplier();
        // アラートメッセージ初期化
        this.alertInstructionContentMessage = "";
        this.alertChangedSupplierMessage = "";
        this.alertChangedLot = "";
        this.alertChangedItemCdMessage = "";
        this.alertMessage = "";
      } else {
        this.instructionContentSelected = newValue;
        this.changedStatus();
        this.setItemCdChange();
        this.setChangedSrhSupplier();
        // アラートメッセージ初期化
        this.alertInstructionContentMessage = "";
        this.alertChangedSupplierMessage = "";
        this.alertChangedLot = "";
        this.alertChangedItemCdMessage = "";
        this.alertMessage = "";
      }
    },
    dialog(val) {
      val || this.close();
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else if (this.inputList.length != 0) {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";

          // this.sortItem = appConfig.P_STC_003_SORT[sortBy] + ascdesc; // + ascdesc;

          if (sortBy == "warehouse") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.warehouse < b.warehouse) {
                  return -1;
                } else if (a.warehouse > b.warehouse) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.warehouse > b.warehouse) {
                  return -1;
                } else if (a.warehouse < b.warehouse) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "location") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.location < b.location) {
                  return -1;
                } else if (a.location > b.location) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.location > b.location) {
                  return -1;
                } else if (a.location < b.location) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "status") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.status < b.status) {
                  return -1;
                } else if (a.status > b.status) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.status > b.status) {
                  return -1;
                } else if (a.status < b.status) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "stockQty") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                return a.stockQty - b.stockQty;
              } else {
                return b.stockQty - a.stockQty;
              }
            });
          } else if (sortBy == "lot") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.lot < b.lot) {
                  return -1;
                } else if (a.lot > b.lot) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.lot > b.lot) {
                  return -1;
                } else if (a.lot < b.lot) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "enteringDate") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                if (a.enteringDate < b.enteringDate) {
                  return -1;
                } else if (a.enteringDate > b.enteringDate) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.enteringDate > b.enteringDate) {
                  return -1;
                } else if (a.enteringDate < b.enteringDate) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "inputQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == " asc") {
                return a.inputQuantity - b.inputQuantity;
              } else {
                return b.inputQuantity - a.inputQuantity;
              }
            });
          }
        }
      },
      deep: true,
    },
    // 検索エリア：品番・品名
    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.itemCdList = list.concat(result[0]);
              this.itemCdList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.itemCdList.length = 0;
        }
      }
    },
    suppliersSelected(newValue) {
      //品番/品名クリア
      this.itemCdSelected = "";
      this.itemCdList = [];
      if (newValue || null) {
        this.setInstructionNo();
        this.alertSupplierMessage = "";
        this.setChangedSupplierList();
      } else {
        this.setInstructionNo();
        this.alertSupplierMessage = "";
        this.setChangedSupplierList();
      }
    },
    itemCdSelected() {
      this.alertItemCdMessage = "";
    },
    changedStatusSelected() {
      this.alertMessage = "";
    },
    inputQuantity() {
      this.alertInputQuantity = "";
    },
    changedItemCdSelected() {
      this.alertChangedItemCdMessage = "";
    },
    changedLot() {
      this.alertChangedLot = "";
    },
    changedSuppliersSelected() {
      this.alertChangedSupplierMessage = "";
    },
    // 変更エリア：変更後品番・品名
    afterItemCdSearch(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.changedProductHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.changedItemCdList = list.concat(result[0]);
              this.changedItemCdList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.changedItemCdList.length = 0;
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-textarea ::v-deep {
  resize: none;
  font-size: large;
  overflow: visible;
  height: 100px;
}

.toatlNum {
  margin-left: 10px;
}

.nowTime {
  font-weight: bold;
  font-size: large;
}

.strageQty {
  font-size: medium;
}

.pTitle {
  font-size: 15px;
  font-weight: lighter;
  margin: 12px 0px 12px 0px;
}
</style>
